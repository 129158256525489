import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'fileInput', 'usageRightsInput' ]

  fileChanged() {
    if (this.fileInputTarget.files.length > 0) {
      this.usageRightsInputTarget.setAttribute('required', 'true')
    }
  }
}
