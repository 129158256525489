document.addEventListener('trix-attachment-add', (event) => {
	if (!event.attachment.file) {
		// Deny HTML attachments (e.g. pasted images)
		event.attachment.remove()
	}
});
document.addEventListener('trix-file-accept', (event) => {
	// Deny file attachments
	event.preventDefault();
});
document.addEventListener('DOMContentLoaded', () => {
	// Open all external trix links in new pages/tabs
	const trixLinks = document.querySelectorAll('.trix-content a');
	Array.prototype.forEach.call(trixLinks, (el, i) => {
		if (el.host !== window.location.host) {
			el.setAttribute('target', '_blank');
		}
	});
});
