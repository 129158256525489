import GLightbox from 'glightbox';

document.addEventListener('DOMContentLoaded', () => {
	if (document.getElementById('mediagallery') === null) {
		return;
	}

	// Image gallery
	const mediaGalleryLightbox = GLightbox({
		selector: '.glightbox-mediagallery'
	});
	mediaGalleryLightbox.on('slide_changed', ({ prev, current }) => {
		// console.log('Current slide', current);
	});
});
