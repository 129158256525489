import GLightbox from '../glightbox';
import Splide from '@splidejs/splide';

document.addEventListener('DOMContentLoaded', () => {
	if (document.getElementById('article') === null)
		return;

	// Image Slider
	const splide = new Splide('.splide', {
		arrows: false,
		pagination: false,
		perPage: 1,
		speed: 800,
	} ).mount();

	const thumbnails = document.querySelectorAll('img.media-item-thumbnail');

	// Google Maps
	let mapHeight = '70vh';
	let mapWidth = '80vw';
	if (/Android|iPhone|iPad/i.test(navigator.userAgent)) {
		mapWidth = '90%';
	}
	const mapLightbox = GLightbox({
		height: mapHeight,
		width: mapWidth,
		selector: '#maplink',
	});

	splide.on('move', newIndex => {
		thumbnails.forEach((thumbnail, n) => {
			if (newIndex === n) {
				document.getElementById('mediaitem-description').textContent = thumbnail.dataset.description;
				document.getElementById('metadata-date').textContent = thumbnail.dataset.date;
				document.getElementById('metadata-author').textContent = thumbnail.dataset.author || 'Unbekannt';

				// Show or hide source row
				const sourceLabel = document.getElementById('metadata-source-label');
				const sourceData = document.getElementById('metadata-source');
				sourceLabel.style.display = thumbnail.dataset.source ? 'block' : 'none';
				sourceData.textContent = thumbnail.dataset.source || '';
				sourceData.style.display = thumbnail.dataset.source ? 'block' : 'none';

				// Update map link target and show/hide depending on presence
				const mapLink = document.getElementById('maplink');
				const geodataLabel = document.getElementById('metadata-geodata-label');
				const geodataLink = document.getElementById('metadata-geodata-link');
				if (thumbnail.dataset.gmapsurl) {
					// Replace map link element to clear old event listeners which might leak otherwise
					const updatedMapLink = mapLink.cloneNode(true);
					updatedMapLink.setAttribute('href', thumbnail.dataset.gmapsurl);
					mapLink.replaceWith(updatedMapLink);
					geodataLink.style.display = 'block';
					geodataLabel.style.display = 'block';
					// Reload GLightbox
					mapLightbox.reload();
				}
				else {
					geodataLink.style.display = 'none';
					geodataLabel.style.display = 'none';
				}

				thumbnail.classList.add('selected');
			}
			else
				thumbnail.classList.remove('selected');
		});
	} );

	thumbnails.forEach((thumbnail, n) => {
		thumbnail.addEventListener('click', e => { splide.go(n); });
	});

	// Image gallery desktop
	const galleryLightbox = GLightbox({
		selector: '.glightbox-gallery'
	});
	galleryLightbox.on('slide_changed', ({ prev, current }) => {
		const { slideIndex } = current;
		splide.go(slideIndex);
	});

	// Image gallery mobile
	const galleryLightboxMobile = GLightbox({
		selector: '.glightbox-gallery-mobile',
	});
	galleryLightboxMobile.on('slide_changed', ({ prev, current }) => {
		const { slideIndex } = current;
		splide.go(slideIndex);
	});

	// Back link
	const backLink = document.querySelector('a.back-link');
	if (backLink) {
		backLink.addEventListener('click', e => {
			if (window.history.length > 1 && (document.referrer == '' || (document.referrer.length > 0 && document.referrer.indexOf(location.protocol + '//' + location.host) !== -1))) {
				window.history.go(-1)
				e.preventDefault();
			}
		});
	}
});
